import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IItems } from 'repository';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private readonly _http: HttpClient) {}

  getAllList(): Observable<IItems<string>> {
    return this._http.get<IItems<string>>(
      `${environment.accountsUrl}/api/subscriptions/products`
    );
  }

  getListBySubscriptionsId(id: number): Observable<IItems<string>> {
    return this._http.get<IItems<string>>(
      `${environment.accountsUrl}/api/subscriptions/${id}/products`
    );
  }
}
